<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>Add a new tutor group</h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <form class="user-form" v-on:submit="saveTutorGroup">
            <div class="grid-x grid-padding-x">
              <div class="cell small-12 medium-6">
                <label>Name<sup class="text-red">*</sup></label>
                <input required type="text" v-model="tutorGroup.name">
                <label>Course<sup class="text-red">*</sup></label>
                <select required v-model="tutorGroup.course">
                  <option value="0">Please choose:</option>
                  <option value="iqa">IQA Course</option>
                  <option value="ass">Assessing Certificate</option>
                  <option value="aa">Assessing Award</option>
                </select>
                <label>Notes</label>
                <vue-editor
                  id="notesEditor"
                  v-bind:editorToolbar="customToolbar"
                  v-model="tutorGroup.notes"
                  placeholder="Notes text"
                  rows="12">
                </vue-editor>
                <button class="button marg-top">Save tutor group</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
      <span class="close-message" v-on:click="clearMessage">X</span>
      <p class="message-text">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'AddTutorGroup',
  data() {
    return {
      tutorGroup: {
        name: '',
        notes: '',
      },
      message: '',
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: '1' }, { header: '2' }],
        ['link'],
      ],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    saveTutorGroup(event) {
      event.preventDefault();
      axios.post(`/tutorGroups/create.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, this.tutorGroup)
        .then((response) => {
          if (response.data.response === true) {
            this.message = 'The new tutor group has been added successfully';
            this.resetTutorGroup();
          } else {
            this.message = 'The new tutor group could not be added - please check the details';
          }
        });
    },
    resetTutorGroup() {
      this.user = {
        name: '',
        notes: '',
      };
    },
    clearMessage() {
      this.message = '';
    },
  },
};
</script>
